import { Menu, Transition } from "@headlessui/react"
import { Fragment, useContext } from "react"
import { ChevronDownIcon } from "@heroicons/react/solid"
import { LanguageContext } from "@contexts/language-context"

// Source: https://headlessui.dev/react/menu
export default function LanguageDropdown() {
  const [language, setLanguage] = useContext(LanguageContext)

  return (
    <div className="">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-gray-50 hover:text-gray-100 rounded-md bg-black bg-opacity-30 hover:bg-opacity-20 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
            Language 語言
            <ChevronDownIcon
              className="w-5 h-5 ml-2 -mr-1"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 w-32 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-1 py-1 ">
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active
                        ? "bg-black opacity-20 text-gray-50"
                        : language === "en"
                        ? "bg-blue-light text-gray-50"
                        : "text-gray-900"
                    } group rounded-md items-center w-full px-2 py-2 text-sm`}
                    onClick={() => setLanguage("en")}
                  >
                    English
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active
                        ? "bg-black opacity-20 text-gray-50"
                        : language === "tc"
                        ? "bg-blue-light text-gray-50"
                        : "text-gray-900"
                    } group rounded-md items-center w-full px-2 py-2 text-sm`}
                    onClick={() => setLanguage("tc")}
                  >
                    中文
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}
