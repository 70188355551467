import { useContext } from "react"
import { LanguageContext } from "@contexts/language-context"

export default function Footer() {
  const [language] = useContext(LanguageContext)

  return (
    <footer className="flex items-center justify-center w-full h-12 border-t bg-white">
      <a
        className="flex items-center justify-center text-sm font-semibold text-gray-500"
        href="https://sehji.co/terms-of-use-for-sehji-website-and-search-tool/"
        target="_blank"
        rel="noopener noreferrer"
      >
        {language === "en" ? "Terms & Conditions" : "條款及細則"}
      </a>
    </footer>
  )
}
