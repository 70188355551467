import { useContext, useEffect } from "react"
import { useLocation, useHistory } from "react-router-dom"
import { Dialog } from "@headlessui/react"
import Popup from "./popup"
import cloneDeep from "lodash/cloneDeep"
import { LanguageContext } from "@contexts/language-context"
import {
  AdvancedSearchContext,
  AdvancedSearchDefaultOptions,
  AdvancedSearchOptions,
} from "@contexts/advanced-search-context"
import Select from "react-select"
import moment from "moment"
import * as gtag from "@utils/gtag"
import { XCircleIcon } from "@heroicons/react/solid"
import * as locales from "react-date-range/dist/locale"
import { Calendar } from "react-date-range"
import "react-date-range/dist/styles.css" // react-date-range main css file
import "react-date-range/dist/theme/default.css" // react-date-range theme css file

export default function AdvancedSearch({ isOpen, setIsOpen, keyword }) {
  const [language] = useContext(LanguageContext)
  const [selectedSearchOptions, setSelectedSearchOptions] = useContext(
    AdvancedSearchContext
  )
  const sortings = AdvancedSearchOptions.sortings
  const filters = AdvancedSearchOptions.filters

  const params = new URLSearchParams(useLocation().search)
  const sortFieldFromUrl = params.get("sortField")
  const sortDirectionFromUrl = params.get("sortDirection")
  const filterKeysFromUrl = params.getAll("filterKeys")
  const filterValuesFromUrl = params.getAll("filterValues")
  const fundingAmountMinFromUrl = params.get("fundingAmountMin")
  const fundingAmountMaxFromUrl = params.get("fundingAmountMax")
  const startDateFromUrl = params.get("startFromDate")

  // We need to convert the array into string in order to use for useEffect's invalidation array,
  // otherwise useEffect will be called forever.
  const filterKeysFromUrlString = JSON.stringify(filterKeysFromUrl)
  const filterValuesFromUrlString = JSON.stringify(filterValuesFromUrl)

  const history = useHistory()

  // Set the advanced search options based on the url parameters
  useEffect(() => {
    // Fill Sorting
    let selectedSorting = selectedSearchOptions.sortedBy
    let sortingFromUrl
    sortings.forEach((sorting) => {
      if (
        (sorting.field.en === sortFieldFromUrl ||
          sorting.field.tc === sortFieldFromUrl) &&
        sorting.direction === sortDirectionFromUrl
      ) {
        sortingFromUrl = sorting
      }
    })

    if (sortingFromUrl) {
      selectedSorting = sortingFromUrl
    }

    // Fill Filters
    let selectedFilters = cloneDeep(selectedSearchOptions.filteredBy)
    filterKeysFromUrl.forEach((filterKeyFromUrl, index) => {
      if (filterValuesFromUrl.length <= index) {
        return
      }

      let filterValueFromUrl = filterValuesFromUrl[index]

      for (const [filterKey, filter] of Object.entries(filters)) {
        switch (filter.type) {
          case "single select":
            if (
              filter.options[0].field.en === filterKeyFromUrl ||
              filter.options[0].field.tc === filterKeyFromUrl
            ) {
              filter.options.forEach((option) => {
                if (
                  option.value.en === filterValueFromUrl ||
                  option.value.tc === filterValueFromUrl
                ) {
                  selectedFilters[filterKey] = option
                }
              })
            }
            break
          default:
            break
        }
      }
    })

    // Fill Funding Amount Range
    let minAmount = selectedSearchOptions.fundingAmountRange.min
    if (
      !isNaN(Number(fundingAmountMinFromUrl)) &&
      Number(fundingAmountMinFromUrl) > 0
    ) {
      minAmount = fundingAmountMinFromUrl
    }

    let maxAmount = selectedSearchOptions.fundingAmountRange.max
    if (
      !isNaN(Number(fundingAmountMaxFromUrl)) &&
      Number(fundingAmountMaxFromUrl) > 0
    ) {
      maxAmount = fundingAmountMaxFromUrl
    }

    let selectedFundingAmountRange = {
      min: minAmount,
      max: maxAmount,
    }

    // Fill Start Date
    let selectedStartDate = selectedSearchOptions.startDate
    if (moment(startDateFromUrl, "DD/MM/YYYY").isValid()) {
      selectedStartDate = startDateFromUrl
    }

    setSelectedSearchOptions({
      sortedBy: selectedSorting,
      filteredBy: selectedFilters,
      fundingAmountRange: selectedFundingAmountRange,
      startDate: selectedStartDate,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sortFieldFromUrl,
    sortDirectionFromUrl,
    filterKeysFromUrlString,
    filterValuesFromUrlString,
    fundingAmountMinFromUrl,
    fundingAmountMaxFromUrl,
    startDateFromUrl,
  ])

  const updateSorting = (selectedSorting) => {
    let selectedFilter = selectedSearchOptions.filteredBy
    let selectedFundingAmountRange = selectedSearchOptions.fundingAmountRange
    let selectedstartDate = selectedSearchOptions.startDate

    let updatedSearchOptions = {
      sortedBy: selectedSorting,
      filteredBy: selectedFilter,
      fundingAmountRange: selectedFundingAmountRange,
      startDate: selectedstartDate,
    }

    setSelectedSearchOptions(updatedSearchOptions)
  }

  const updateFilters = (filterKey, selectedOption) => {
    let selectedSorting = selectedSearchOptions.sortedBy
    let selectedFundingAmountRange = selectedSearchOptions.fundingAmountRange
    let selectedstartDate = selectedSearchOptions.startDate

    let updatedFilters = cloneDeep(selectedSearchOptions.filteredBy)
    updatedFilters[filterKey] = selectedOption

    let updatedSearchOptions = {
      sortedBy: selectedSorting,
      filteredBy: updatedFilters,
      fundingAmountRange: selectedFundingAmountRange,
      startDate: selectedstartDate,
    }

    setSelectedSearchOptions(updatedSearchOptions)
  }

  const updateFundingAmountRange = (minValue, maxValue) => {
    let selectedSorting = selectedSearchOptions.sortedBy
    let selectedFilter = selectedSearchOptions.filteredBy
    let selectedstartDate = selectedSearchOptions.startDate

    let updatedFundingAmountRange = {
      min: minValue,
      max: maxValue,
    }

    let updatedSearchOptions = {
      sortedBy: selectedSorting,
      filteredBy: selectedFilter,
      fundingAmountRange: updatedFundingAmountRange,
      startDate: selectedstartDate,
    }

    setSelectedSearchOptions(updatedSearchOptions)
  }

  const updateStartDate = (dateString) => {
    let selectedSorting = selectedSearchOptions.sortedBy
    let selectedFilter = selectedSearchOptions.filteredBy
    let selectedFundingAmountRange = selectedSearchOptions.fundingAmountRange

    let updatedSearchOptions = {
      sortedBy: selectedSorting,
      filteredBy: selectedFilter,
      fundingAmountRange: selectedFundingAmountRange,
      startDate: dateString,
    }

    setSelectedSearchOptions(updatedSearchOptions)
  }

  const filteringField = (filterKey, index) => {
    let filter = filters[filterKey]
    switch (filter.type) {
      case "single select":
        let fieldName = filter.fieldName[language]
        let options = filter.options.map((option) => {
          return {
            label: language === "en" ? option.label.en : option.label.tc,
            value: option,
          }
        })
        let selectedOption = selectedSearchOptions.filteredBy[filterKey]

        return (
          <div key={index} className="mr-2 mb-2">
            <p className="text-base font-semibold text-gray-700 mb-2">
              {fieldName}
            </p>
            <Select
              isSearchable={false}
              value={{
                label:
                  language === "en"
                    ? selectedOption.label.en
                    : selectedOption.label.tc,
                value: selectedOption,
              }}
              options={options}
              onChange={(option) => updateFilters(filterKey, option.value)}
            />
          </div>
        )
      default:
        return <></>
    }
  }

  const search = (keyword) => {
    gtag.event({
      action: "advanced_search",
      category: "Search",
      label: keyword,
    })

    let filterKeysQuery = ""
    let filterValuesQuery = ""
    // eslint-disable-next-line no-unused-vars
    for (const [_, object] of Object.entries(
      selectedSearchOptions.filteredBy
    )) {
      let localizaedKey = language === "en" ? object.field.en : object.field.tc
      let localizaedValue =
        language === "en" ? object.value.en : object.value.tc

      if (!localizaedValue || localizaedValue.trim() === "") {
        continue
      }

      filterKeysQuery = filterKeysQuery.concat(`&filterKeys=${localizaedKey}`)
      filterValuesQuery = filterValuesQuery.concat(
        `&filterValues=${localizaedValue}`
      )
    }

    let fundingAmountQuery = ""
    if (
      (!isNaN(Number(selectedSearchOptions.fundingAmountRange.min)) &&
        Number(selectedSearchOptions.fundingAmountRange.min) > 0) ||
      (!isNaN(Number(selectedSearchOptions.fundingAmountRange.max)) &&
        Number(selectedSearchOptions.fundingAmountRange.max) > 0)
    ) {
      fundingAmountQuery = `&fundingAmountMin=${selectedSearchOptions.fundingAmountRange.min}&fundingAmountMax=${selectedSearchOptions.fundingAmountRange.max}`
    }

    let startDateQuery = ""
    if (moment(selectedSearchOptions.startDate, "DD/MM/YYYY").isValid()) {
      startDateQuery = `&startFromDate=${selectedSearchOptions.startDate}`
    }

    history.push(
      `/search?keyword=${keyword}&sortField=${selectedSearchOptions.sortedBy.field[language]}&sortDirection=${selectedSearchOptions.sortedBy.direction}${filterKeysQuery}${filterValuesQuery}${fundingAmountQuery}${startDateQuery}`
    )
  }

  return (
    <Popup isOpen={isOpen} onClose={() => {}}>
      <div className="inline-block w-full max-w-2xl min-h-screen md:min-h-0 p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
        <div className="flex flex-row-reverse">
          <button
            className="focus:outline-none"
            onClick={() => setIsOpen(false)}
          >
            <XCircleIcon className="w-7 text-gray-400 hover:text-gray-300 mt-4 mb-4" />
          </button>
        </div>

        <Dialog.Title className="text-2xl font-bold leading-6 text-gray-900 mb-10">
          {language === "en" ? "Advanced Search Settings" : "進階搜尋設定"}
        </Dialog.Title>

        <div className="flex flex-col">
          <p className="text-lg font-semibold text-gray-900 mb-2">
            {language === "en" ? "Sorted By" : "排序"}
          </p>
          <Select
            isSearchable={false}
            value={{
              label: selectedSearchOptions.sortedBy.displayName[language],
              value: selectedSearchOptions.sortedBy,
            }}
            options={sortings.map((sorting) => {
              return {
                label: sorting.displayName[language],
                value: sorting,
              }
            })}
            onChange={(option) => updateSorting(option.value)}
          />
        </div>

        <div className="mt-10">
          <p className="text-lg font-semibold text-gray-900 mb-2">
            {language === "en" ? "Filtered By" : "篩選"}
          </p>
          <div className="grid xl:grid-cols-2 gap-4">
            {Object.keys(filters).map(filteringField)}
          </div>
        </div>

        <div className="mt-10">
          <p className="text-lg font-semibold text-gray-900 mb-2">
            {language === "en" ? "Funding Amount" : "資助金額"}
          </p>
          <div className="grid xl:grid-cols-2 gap-4">
            <div className="flex flex-wrap items-center">
              <p className="text-base font-medium text-gray-700 mb-2 mr-2">
                {language === "en" ? "Minimum Amount" : "最低金額"}
              </p>
              <input
                key="fundingAmountMin"
                type="number"
                value={selectedSearchOptions.fundingAmountRange.min}
                onChange={(event) =>
                  updateFundingAmountRange(
                    event.target.value,
                    selectedSearchOptions.fundingAmountRange.max
                  )
                }
              ></input>
            </div>
            <div className="flex flex-wrap items-center">
              <p className="text-base font-medium text-gray-700 mb-2 mr-2">
                {language === "en" ? "Maximum Amount" : "最高金額"}
              </p>
              <input
                key="fundingAmountMax"
                type="number"
                value={selectedSearchOptions.fundingAmountRange.max}
                onChange={(event) =>
                  updateFundingAmountRange(
                    selectedSearchOptions.fundingAmountRange.min,
                    event.target.value
                  )
                }
              ></input>
            </div>
          </div>
        </div>

        <div className="mt-10">
          <p className="text-lg font-semibold text-gray-900">
            {language === "en" ? "Start From Date" : "開始日期"}
          </p>
          <Calendar
            className="mr-2"
            locale={language === "en" ? locales.enUS : locales.zhTW}
            date={
              selectedSearchOptions.startDate === ""
                ? null
                : moment(selectedSearchOptions.startDate, "DD/MM/YYYY").toDate()
            }
            onChange={(item) =>
              updateStartDate(moment(item).format("DD/MM/YYYY"))
            }
          />
        </div>

        <div className="flex flex-wrap items-center mt-12 mb-12">
          <button
            type="button"
            className="mr-4 inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-Default border border-transparent rounded-md hover:bg-blue-light active:bg-blue-dark"
            onClick={() => {
              search(keyword)
              setIsOpen(false)
            }}
          >
            {language === "en" ? "Apply" : "套用"}
          </button>

          <button
            type="button"
            className="mr-4 inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-gray-400 border border-transparent rounded-md hover:bg-gray-300 active:bg-gray-500"
            onClick={() => {
              setSelectedSearchOptions(AdvancedSearchDefaultOptions)
            }}
          >
            {language === "en" ? "Reset" : "重設"}
          </button>

          <button
            type="button"
            className="mr-4 inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-gray-400 border border-transparent rounded-md hover:bg-gray-300 active:bg-gray-500"
            onClick={() => setIsOpen(false)}
          >
            {language === "en" ? "Close" : "關閉"}
          </button>
        </div>
      </div>
    </Popup>
  )
}
