import { React, useState, useEffect, useContext } from "react"
import { useHistory, useLocation } from "react-router-dom"
import * as gtag from "@utils/gtag"
import { LanguageContext } from "@contexts/language-context"
import { AdvancedSearchContext } from "@contexts/advanced-search-context"
import { CogIcon } from "@heroicons/react/outline"
import AdvancedSearch from "./advanced-search"
import moment from "moment"

export default function SearchForm() {
  const params = new URLSearchParams(useLocation().search)
  const keywordFromUrl = params.get("keyword")

  const history = useHistory()
  const [isSearchFieldActive, setIsSearchFieldActive] = useState(false)
  const [keyword, setKeyword] = useState("")
  const [isAdvancedSearchOpen, setIsAdvancedSearchOpen] = useState(false)

  const [language] = useContext(LanguageContext)
  const [selectedSearchOptions] = useContext(AdvancedSearchContext)

  useEffect(() => {
    setKeyword(keywordFromUrl ?? "")
  }, [keywordFromUrl])

  const search = async (event) => {
    event.preventDefault()
    document.activeElement.blur()

    let keyword = event.target.keyword.value.trim()

    // if (keyword === "") {
    //   return
    // }

    gtag.event({
      action: "perform_search",
      category: "Search",
      label: keyword,
    })

    let filterKeysQuery = ""
    let filterValuesQuery = ""
    // eslint-disable-next-line no-unused-vars
    for (const [_, object] of Object.entries(
      selectedSearchOptions.filteredBy
    )) {
      let localizaedKey = language === "en" ? object.field.en : object.field.tc
      let localizaedValue =
        language === "en" ? object.value.en : object.value.tc

      if (!localizaedValue || localizaedValue.trim() === "") {
        continue
      }

      filterKeysQuery = filterKeysQuery.concat(`&filterKeys=${localizaedKey}`)
      filterValuesQuery = filterValuesQuery.concat(
        `&filterValues=${localizaedValue}`
      )
    }

    let fundingAmountQuery = ""
    if (
      (!isNaN(Number(selectedSearchOptions.fundingAmountRange.min)) &&
        Number(selectedSearchOptions.fundingAmountRange.min) > 0) ||
      (!isNaN(Number(selectedSearchOptions.fundingAmountRange.max)) &&
        Number(selectedSearchOptions.fundingAmountRange.max) > 0)
    ) {
      fundingAmountQuery = `&fundingAmountMin=${selectedSearchOptions.fundingAmountRange.min}&fundingAmountMax=${selectedSearchOptions.fundingAmountRange.max}`
    }

    let startDateQuery = ""
    if (moment(selectedSearchOptions.startDate, "DD/MM/YYYY").isValid()) {
      startDateQuery = `&startFromDate=${selectedSearchOptions.startDate}`
    }

    history.push(
      `/search?keyword=${keyword}&sortField=${selectedSearchOptions.sortedBy.field[language]}&sortDirection=${selectedSearchOptions.sortedBy.direction}${filterKeysQuery}${filterValuesQuery}${fundingAmountQuery}${startDateQuery}`
    )
  }

  const searchBarStyle = () => {
    if (isSearchFieldActive) {
      return "w-full flex items-center h-12 lg:h-14 mb-1 rounded-full border-gray-100 bg-white border-2"
    } else {
      return "w-full flex items-center h-12 lg:h-14 mb-1 rounded-full bg-gray-100 border-transparent border-2"
    }
  }

  return (
    <form onSubmit={search}>
      <div className="flex flex-col">
        <div className={searchBarStyle()}>
          <input
            id="keyword"
            type="text"
            className="w-full ml-5 mr-5 bg-transparent border-transparent focus:border-transparent focus:bg-transparent focus:ring-0"
            placeholder={
              language === "en"
                ? "project title, organisation, etc."
                : "項目的關鍵詞、機構名稱"
            }
            value={keyword}
            onChange={(event) => setKeyword(event.target.value)}
            onFocus={() => setIsSearchFieldActive(true)}
            onBlur={() => setIsSearchFieldActive(false)}
          />

          <button
            type="button"
            className="mr-5 hidden"
            onClick={() => setIsAdvancedSearchOpen(true)}
          >
            <CogIcon className={`w-7 justify-center text-gray-500`} />
          </button>
        </div>
        <div className="w-full grid grid-cols-4 items-end mt-4 lg:mt-6">
          <button
            type="submit"
            className="col-span-4 h-12 lg:h-14 bg-blue-Default text-white font-normal rounded-full hover:bg-blue-light active:bg-blue-Default focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            // className="col-span-4 lg:col-span-3 h-12 lg:h-14 lg:mr-4 bg-blue-Default text-white font-normal rounded-full hover:bg-blue-light active:bg-blue-Default focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          >
            {language === "en" ? "Search" : "搜尋"}
          </button>

          {/* <button
            type="button"
            className="col-span-4 lg:col-span-1 mt-2 lg:mt-0 inline-flex justify-center px-4 py-2 text-sm font-medium rounded-full border-blue-Default hover:border-blue-light border-2 text-blue-Default hover:text-blue-light active:text-blue-dark focus:outline-none"
            onClick={() => setIsAdvancedSearchOpen(true)}
          >
            {language === "en" ? "Sort/Filter" : "排序/篩選"}
          </button> */}
        </div>
      </div>
      <AdvancedSearch
        isOpen={isAdvancedSearchOpen}
        setIsOpen={setIsAdvancedSearchOpen}
        keyword={keyword}
      />
    </form>
  )
}
