import React, { useContext } from "react"
import { LanguageContext } from "@contexts/language-context"
import { Dialog } from "@headlessui/react"
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { HomeIcon, XCircleIcon } from "@heroicons/react/solid"

export default function SearchResultItem({
  searchResult,
  isDetail,
  setSeletctedResultItem,
}) {
  const [language] = useContext(LanguageContext)

  if (!searchResult) {
    return <></>
  }

  const grantee = () => {
    let name = ""

    switch (language) {
      case "tc":
        name = searchResult["Grantee Name (Chi)"]
        if (!name || name.trim() === "") {
          name = searchResult["Grantee Name (Eng)"]

          if (!name || name.trim() === "") {
            name = "機構名稱不明"
          }
        }
        break
      default:
        name = searchResult["Grantee Name (Eng)"]
        if (!name || name.trim() === "") {
          name = searchResult["Grantee Name (Chi)"]

          if (!name || name.trim() === "") {
            name = "Organization Name N/A"
          }
        }
    }

    return name
  }

  const grantName = () => {
    let name = ""

    switch (language) {
      case "tc":
        name = searchResult["Grant Name (Chi)"]
        if (!name || name.trim() === "") {
          name = searchResult["Grant Name (Eng)"]

          if (!name || name.trim() === "") {
            name = ""
          }
        }
        break
      default:
        name = searchResult["Grant Name (Eng)"]

        if (!name || name.trim() === "") {
          name = ""
        }
    }

    return name
  }

  const grantCategory = () => {
    let name = ""

    switch (language) {
      case "tc":
        name = searchResult["Category of Grant (Chi)"]
        if (!name || name.trim() === "") {
          name = searchResult["Category of Grant (Eng)"]

          if (!name || name.trim() === "") {
            name = ""
          }
        }
        break
      default:
        name = searchResult["Category of Grant (Eng)"]
        if (!name || name.trim() === "") {
          name = searchResult["Category of Grant (Chi)"]

          if (!name || name.trim() === "") {
            name = ""
          }
        }
    }

    return name
  }

  const intermediary = () => {
    let name = ""

    switch (language) {
      case "tc":
        name = searchResult["Intermediary (Chi)"]
        if (!name || name.trim() === "") {
          name = searchResult["Intermediary (Eng)"]

          if (!name || name.trim() === "") {
            name = ""
          }
        }
        break
      default:
        name = searchResult["Intermediary (Eng)"]
        if (!name || name.trim() === "") {
          name = searchResult["Intermediary (Chi)"]

          if (!name || name.trim() === "") {
            name = ""
          }
        }
    }

    return name
  }

  const partnering = () => {
    let name = ""

    switch (language) {
      case "tc":
        name = searchResult["Partnering/Related Organisation Name (Chi)"]
        if (!name || name.trim() === "") {
          name = searchResult["Partnering/Related Organisation Name (Eng)"]

          if (!name || name.trim() === "") {
            name = ""
          }
        }
        break
      default:
        name = searchResult["Partnering/Related Organisation Name (Eng)"]
        if (!name || name.trim() === "") {
          name = searchResult["Partnering/Related Organisation Name (Chi)"]

          if (!name || name.trim() === "") {
            name = ""
          }
        }
    }

    if (name !== "") {
      name = `${
        language === "en" ? "Partnering Oganisation: " : "合作機構: "
      }`.concat(name)
    }
    return name
  }

  const district = () => {
    let name = ""
    let districts

    switch (language) {
      case "tc":
        districts = searchResult["Project District (Chi)"]
        if (!districts || districts.length === 0) {
          name = "香港"
        } else {
          name = districts.join(", ")
          if (name.trim() === "") {
            name = "香港"
          }
        }
        break
      default:
        districts = searchResult["Project District (Eng)"]
        if (!districts || districts.length === 0) {
          name = "Hong Kong"
        } else {
          name = districts.join(", ")
          if (name.trim() === "") {
            name = "Hong Kong"
          }
        }
    }

    return `${language === "en" ? "Project District: " : "項目地區: "}`.concat(
      name
    )
  }

  const artForm = () => {
    let name = ""
    let artforms

    switch (language) {
      case "tc":
        artforms = searchResult["Art Form (Chi)"]
        if (!!artforms && artforms.length > 0) {
          name = artforms.join(", ")
        } else {
          artforms = searchResult["Art Form (Eng)"]
          if (!!artforms && artforms.length > 0) {
            name = artforms.join(", ")
          }
        }
        break
      default:
        artforms = searchResult["Art Form (Eng)"]
        if (!!artforms && artforms.length > 0) {
          name = artforms.join(", ")
        } else {
          artforms = searchResult["Art Form (Chi)"]
          if (!!artforms && artforms.length > 0) {
            name = artforms.join(", ")
          }
        }
    }

    if (name !== "") {
      name = `${language === "en" ? "Art Form: " : "藝術形式: "}`.concat(name)
    }
    return name
  }

  const projectDescription = () => {
    let name = ""

    switch (language) {
      case "tc":
        name = searchResult["Project Description (Chi)"]
        if (!name || name.trim() === "") {
          name = searchResult["Project Description (Eng)"]

          if (!name || name.trim() === "") {
            name = ""
          }
        }
        break
      default:
        name = searchResult["Project Description (Eng)"]
        if (!name || name.trim() === "") {
          name = searchResult["Project Description (Chi)"]

          if (!name || name.trim() === "") {
            name = ""
          }
        }
    }

    return name
  }

  const outcome = () => {
    let name = ""

    switch (language) {
      case "tc":
        name = searchResult["Outcome (Chi)"]
        if (!name || name.trim() === "") {
          name = searchResult["Outcome (Eng)"]

          if (!name || name.trim() === "") {
            name = ""
          }
        }
        break
      default:
        name = searchResult["Outcome (Eng)"]
        if (!name || name.trim() === "") {
          name = searchResult["Outcome (Chi)"]

          if (!name || name.trim() === "") {
            name = ""
          }
        }
    }

    return name
  }

  const impact = () => {
    let name = ""

    switch (language) {
      case "tc":
        name = searchResult["Impact (Chi)"]
        if (!name || name.trim() === "") {
          name = searchResult["Impact (Eng)"]

          if (!name || name.trim() === "") {
            name = ""
          }
        }
        break
      default:
        name = searchResult["Impact (Eng)"]
        if (!name || name.trim() === "") {
          name = searchResult["Impact (Chi)"]

          if (!name || name.trim() === "") {
            name = ""
          }
        }
    }

    return name
  }

  const projectName = () => {
    let name = ""

    switch (language) {
      case "tc":
        name = searchResult["Project Title (Chi)"]
        if (!name || name.trim() === "") {
          name = searchResult["Project Title (Eng)"]

          if (!name || name.trim() === "") {
            name = "項目名稱不明"
          }
        }
        break
      default:
        name = searchResult["Project Title (Eng)"]
        if (!name || name.trim() === "") {
          name = searchResult["Project Title (Chi)"]

          if (!name || name.trim() === "") {
            name = "Project Name N/A"
          }
        }
    }

    return name
  }

  const projectPeriod = () => {
    if (
      !searchResult["Relevant Date"] ||
      searchResult["Relevant Date"].trim() === ""
    ) {
      return ""
    }

    return searchResult["Relevant Date"]
  }

  const fundingPeriod = () => {
    let text = searchResult["Funding Period"]

    if (!text || text.trim() === "") {
      text = ""
    } else {
      let prefix = language === "en" ? "Funding Period: " : "資助期: "
      text = prefix.concat(text)
    }

    return text
  }

  const startDate = () => {
    let text = searchResult["Start Date"]

    if (!text || text.trim() === "") {
      text = ""
    } else {
      let prefix = language === "en" ? "Start Date: " : "起始日期: "
      text = prefix.concat(text)
    }

    return text
  }

  const endDate = () => {
    let text = searchResult["End Date"]

    if (!text || text.trim() === "") {
      text = ""
    } else {
      let prefix = language === "en" ? "End Date: " : "完結日期: "
      text = prefix.concat(text)
    }

    return text
  }

  const projectSummary = () => {
    let summary = ""

    switch (language) {
      case "tc":
        summary = searchResult["Project Summary (Chi)"]
        if (!summary || summary.trim() === "") {
          summary = searchResult["Project Summary (Eng)"]

          if (!summary || summary.trim() === "") {
            summary = ""
          }
        }
        break
      default:
        summary = searchResult["Project Summary (Eng)"]
        if (!summary || summary.trim() === "") {
          summary = searchResult["Project Summary (Chi)"]

          if (!summary || summary.trim() === "") {
            summary = ""
          }
        }
    }

    return summary
  }

  const projectFundedAmount = () => {
    if (
      !searchResult["Funding Amount"] ||
      searchResult["Funding Amount"].length === 0
    ) {
      return ""
    }

    let amount = "HKD $".concat(searchResult["Funding Amount"])
    return amount
  }

  const projectDescriptionView = () => {
    if (projectDescription()) {
      return (
        <div className="mb-8">
          <p className="text-gray-500 font-semibold text-sm mb-1">
            {language === "en" ? "Description" : "簡介"}
          </p>
          <p className="text-gray-700 text-lg">{projectDescription()}</p>
        </div>
      )
    } else {
      return <></>
    }
  }

  const projectOutcomeView = () => {
    if (outcome()) {
      return (
        <div className="mb-8">
          <p className="text-gray-500 font-semibold text-sm mb-1">
            {language === "en" ? "Outcome" : "影響"}
          </p>
          <p className="text-gray-700 text-lg">{outcome()}</p>
        </div>
      )
    } else {
      return <></>
    }
  }

  const projectImpactView = () => {
    if (impact()) {
      return (
        <div className="mb-8">
          <p className="text-gray-500 font-semibold text-sm mb-1">
            {language === "en" ? "Impacts" : "成效"}
          </p>
          <p className="text-gray-700 text-lg">{impact()}</p>
        </div>
      )
    } else {
      return <></>
    }
  }

  const projectFoundingAmountView = () => {
    if (projectFundedAmount()) {
      return (
        <div className="mb-2">
          <p className="text-gray-700 text-sm font-semibold">
            {projectFundedAmount()}
          </p>
        </div>
      )
    } else {
      return <></>
    }
  }

  const projectPeriodView = () => {
    if (projectPeriod()) {
      return (
        <div className="mb-2">
          <p className="text-gray-700 text-sm font-semibold">
            {language === "en" ? "Relevant Date: " : "相關日期: "}
            {projectPeriod()}
          </p>
        </div>
      )
    } else {
      return <></>
    }
  }

  const projectGrantCategoryView = () => {
    if (grantCategory()) {
      return (
        <div className="mb-2">
          <p className="text-gray-700 text-sm font-semibold">
            {grantCategory()}
          </p>
        </div>
      )
    } else {
      return <></>
    }
  }

  const artFormView = () => {
    if (artForm()) {
      return (
        <div className="mb-2">
          <p className="text-sm text-gray-500">{artForm()}</p>
        </div>
      )
    } else {
      return <></>
    }
  }

  const partneringOrganisationView = () => {
    if (partnering()) {
      return (
        <div className="mb-2">
          <p className="text-sm text-gray-500">{partnering()}</p>
        </div>
      )
    } else {
      return <></>
    }
  }

  const grantResultUrlView = () => {
    let grantLink = searchResult["Grant Result URL"]
    if (grantLink) {
      return (
        <a
          href={grantLink}
          target="__blank"
          className="mt-8 inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-Default border border-transparent rounded-md hover:bg-blue-light active:bg-blue-dark"
        >
          {language === "en" ? "Grant Result" : "資助結果"}
        </a>
      )
    } else {
      return <></>
    }
  }

  // eslint-disable-next-line
  const ProjectUrlView = () => {
    let projectUrl = searchResult["Org/Project URL"]
    if (projectUrl) {
      return (
        <p className="text-blue-Default hover:text-blue-light pr-4">
          <a href={projectUrl} target="__blank">
            <HomeIcon className="w-5" />
          </a>
        </p>
      )
    } else {
      return <></>
    }
  }

  // eslint-disable-next-line
  const fbView = () => {
    let fbUrl = searchResult["Org/Project Facebook"]
    if (fbUrl) {
      return (
        <p className="text-blue-Default hover:text-blue-light text-lg font-bold pr-4">
          <a href={fbUrl} target="__blank">
            <FontAwesomeIcon icon={faFacebook} />
          </a>
        </p>
      )
    } else {
      return <></>
    }
  }

  // eslint-disable-next-line
  const igView = () => {
    let igUrl = searchResult["Org/Project IG"]
    if (igUrl) {
      return (
        <p className="text-blue-Default hover:text-blue-light text-lg font-bold pr-4">
          <a href={igUrl} target="__blank">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </p>
      )
    } else {
      return <></>
    }
  }

  const intermediaryView = () => {
    if (intermediary()) {
      return (
        <div className="rounded-md border bg-gray-100 pt-1 pb-1 pl-2 pr-2">
          <p className="text-sm text-gray-500 font-semibold">
            {intermediary()}
          </p>
        </div>
      )
    } else {
      return <></>
    }
  }

  const grantNameView = () => {
    if (grantName()) {
      return (
        <div className="col-span-12 mt-2">
          <p className="text-sm text-gray-500">{grantName()}</p>
        </div>
      )
    } else {
      return <></>
    }
  }

  const grantCategoryView = () => {
    if (grantCategory()) {
      return (
        <div className="col-span-12 mt-2">
          <p className="text-sm text-gray-500">{grantCategory()}</p>
        </div>
      )
    } else {
      return <></>
    }
  }

  const projectSummaryForDetailView = () => {
    if (projectSummary()) {
      return (
        <div className="mb-8">
          <p className="text-gray-700 text-2xl">{projectSummary()}</p>
        </div>
      )
    } else {
      return <></>
    }
  }

  const projectSummaryForListView = () => {
    if (projectSummary()) {
      return (
        <div className="mt-2">
          <p className="text-gray-700 text-lg">{projectSummary()}</p>
        </div>
      )
    } else {
      return <></>
    }
  }

  const fundingPeriodView = () => {
    if (fundingPeriod()) {
      return (
        <div className="mb-2">
          <p className="text-sm text-gray-500">{fundingPeriod()}</p>
        </div>
      )
    } else {
      return <></>
    }
  }

  const startDateView = () => {
    if (startDate()) {
      return (
        <div className="mb-2">
          <p className="text-sm text-gray-500">{startDate()}</p>
        </div>
      )
    } else {
      return <></>
    }
  }

  const endDateView = () => {
    if (endDate()) {
      return (
        <div className="mb-2">
          <p className="text-sm text-gray-500">{endDate()}</p>
        </div>
      )
    } else {
      return <></>
    }
  }

  let view = <></>
  if (!isDetail) {
    view = (
      <div className="grid grid-cols-12">
        <div className="col-span-12 flex flex-row flex-wrap mb-1">
          <div className="pt-1 pb-1 mr-3">
            <p className="text-base font-semibold text-gray-500">{grantee()}</p>
          </div>
          {intermediaryView()}
        </div>
        <div className="col-span-12">
          <p className="text-gray-700 text-2xl font-bold">{projectName()}</p>
        </div>
        <div className="col-span-12">{projectSummaryForListView()}</div>
        {grantNameView()}
        {grantCategoryView()}
        <div className="col-span-12 mt-2">
          <p className="text-sm text-gray-500">{projectFundedAmount()}</p>
        </div>
        <div className="col-span-12">
          <p className="text-xs text-gray-500">{projectPeriod()}</p>
        </div>
      </div>
    )
  } else {
    view = (
      <div className="grid grid-cols-12 p-6">
        <div className="col-span-12 flex flex-row-reverse">
          <button
            onClick={() => {
              setSeletctedResultItem(undefined)
            }}
          >
            <XCircleIcon className="w-7 text-gray-400 hover:text-gray-300 mt-4 mb-4" />
          </button>
        </div>
        <div className="col-span-12 flex flex-row flex-wrap mb-1 md:mb-2">
          <div className="pt-1 pb-1 mr-3">
            <p className="text-lg font-semibold text-gray-500">{grantee()}</p>
          </div>
          {intermediaryView()}
        </div>
        <Dialog.Title className="col-span-12">
          <p className="text-gray-700 text-2xl md:text-4xl font-bold mb-1 md:mb-2">
            {projectName()}
          </p>
        </Dialog.Title>
        {/* <div className="col-span-12 flex flex-wrap items-center mb-8">
          {ProjectUrlView()}
          {fbView()}
          {igView()}
        </div> */}
        <div className="col-span-12">{projectSummaryForDetailView()}</div>
        <div className="col-span-12">{projectDescriptionView()}</div>
        <div className="col-span-12">{projectOutcomeView()}</div>
        <div className="col-span-12">{projectImpactView()}</div>
        <div className="col-span-12">{projectFoundingAmountView()}</div>
        <div className="col-span-12">{projectGrantCategoryView()}</div>
        <div className="col-span-12">{projectPeriodView()}</div>
        <div className="col-span-12 mb-8">
          <p className="text-gray-700 text-sm font-semibold">
            {language === "en" ? "Grant Name: " : "資助名稱: "}
            {grantName()}
          </p>
        </div>
        <div className="col-span-12 mb-2">
          <p className="text-sm text-gray-500">{district()}</p>
        </div>
        <div className="col-span-12">{fundingPeriodView()}</div>
        <div className="col-span-12">{startDateView()}</div>
        <div className="col-span-12">{endDateView()}</div>
        <div className="col-span-12">{artFormView()}</div>
        <div className="col-span-12">{partneringOrganisationView()}</div>
        <div className="col-span-12">{grantResultUrlView()}</div>
      </div>
    )
  }

  return view
}
