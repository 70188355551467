import React, { useContext, useState } from "react"
import { useLocation } from "react-router-dom"
import InfiniteScroll from "react-infinite-scroll-component"
import useSearch from "@hooks/use-search"
import SearchLoadingIndicator from "@components/search-loading-indicator"
import SearchResultItem from "@components/search-result-item"
import { LanguageContext } from "@contexts/language-context"
import Popup from "./popup"
import { faHome } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default function SearchResults() {
  const params = new URLSearchParams(useLocation().search)
  const keyword = params.get("keyword")
  const sortField = params.get("sortField")
  const sortDirection = params.get("sortDirection")
  const filterKeys = params.getAll("filterKeys")
  const filterValues = params.getAll("filterValues")
  const fundingAmountMin = params.get("fundingAmountMin")
  const fundingAmountMax = params.get("fundingAmountMax")
  const startFromDate = params.get("startFromDate")

  const {
    results,
    hasMorePages,
    viewStatus,
    error,
    page,
    loadMore,
  } = useSearch(
    keyword,
    sortField,
    sortDirection,
    filterKeys,
    filterValues,
    fundingAmountMin,
    fundingAmountMax,
    startFromDate
  )

  const [language] = useContext(LanguageContext)
  const [seletctedResultItem, setSeletctedResultItem] = useState(undefined)

  let view = <></>
  switch (viewStatus) {
    case "data":
      view = (
        <div className="w-full">
          <div className="flex items-center mb-1">
            <p className="text-blue-Default hover:text-blue-light text-2xl font-bold pr-4">
              <a href={"/"}>
                <FontAwesomeIcon icon={faHome} />
              </a>
            </p>
            <p className="text-xl text-gray-600 font-bold pr-2">{"›"}</p>
            <p className="text-xl text-gray-600 font-bold">
              {language === "en" ? "Search Results" : "搜尋結果"}
            </p>
          </div>
          <div className="w-full h-1 mb-8 rounded-full bg-gray-200"></div>
          <InfiniteScroll
            dataLength={results.length}
            next={() => loadMore(page + 1)}
            hasMore={hasMorePages}
            loader={<SearchLoadingIndicator />}
          >
            {results.map((result, index) => (
              <div key={index} className="w-full mb-14">
                <button
                  className="w-full text-left focus:outline-none"
                  onClick={() => {
                    setSeletctedResultItem(result)
                  }}
                >
                  <SearchResultItem searchResult={result} />
                </button>
              </div>
            ))}
          </InfiniteScroll>
          <Popup isOpen={!!seletctedResultItem} onClose={() => {}}>
            <div className="my-8 inline-block w-full max-w-2xl md:min-h-0 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <SearchResultItem
                searchResult={seletctedResultItem}
                isDetail={true}
                setSeletctedResultItem={setSeletctedResultItem}
              />
            </div>
          </Popup>
        </div>
      )
      break
    case "error":
      view = (
        <div className="text-center mt-20">
          <p className="text-sm sm:text-base tracking-normal font-bold text-red-200">
            {error}
          </p>
        </div>
      )
      break
    case "empty":
      view = (
        <div className="justify-center mt-20 flex items-center mb-1">
          <p className="text-blue-Default hover:text-blue-light text-sm sm:text-base font-bold pr-2">
            <a href={"/"}>
              <FontAwesomeIcon icon={faHome} />
            </a>
          </p>
          <p className="text-sm sm:text-base tracking-normal font-bold text-gray-600 pr-2">
            {">"}
          </p>
          <p className="text-sm sm:text-base tracking-normal font-bold text-gray-600">
            {language === "en" ? "No results found" : "沒有相關搜尋結果"}
          </p>
        </div>
      )
      break
    default:
      view = (
        <div className="w-full">
          <SearchLoadingIndicator />
        </div>
      )
      break
  }

  return view
}
