import { useContext } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { LanguageContext } from "@contexts/language-context"
import { Link } from "react-router-dom"
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default function VerifiedMessage() {
  const [language] = useContext(LanguageContext)
  const { isAuthenticated, loginWithRedirect } = useAuth0()

  return (
    <div className="flex flex-col justify-center text-center bg-gray-50 p-6 rounded-md mb-20">
      <p className="text-lg font-semibold text-gray-700 mb-2">
        {language === "en"
          ? "Your Account has been verified"
          : "你的帳號已被認證"}
      </p>
      <div className="inline-flex items-center justify-center text-6xl text-gray-500 mb-6">
        <FontAwesomeIcon icon={faCheckSquare} />
      </div>
      {isAuthenticated && (
        <div>
          <Link
            to="/"
            className="text-base font-semibold text-blue-Default hover:text-blue-light focus:outline-none mr-1"
          >
            {language === "en"
              ? "Search the funding projects now"
              : "立即搜尋資助項目"}
          </Link>
        </div>
      )}
      {!isAuthenticated && (
        <div>
          <button
            className="text-base font-semibold text-blue-Default hover:text-blue-light focus:outline-none mr-1"
            onClick={() => loginWithRedirect()}
          >
            {language === "en"
              ? "Login and search the funding projects now"
              : "立即登入以搜尋資助項目"}
          </button>
        </div>
      )}
    </div>
  )
}
