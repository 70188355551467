const GA_TRACKING_ID = process.env.REACT_APP_GA_ID

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }) => {
  window.gtag("event", action, {
    event_category: category,
    event_label: label,
    value: value,
  })
}

export const setUserId = (userId) => {
  window.gtag("config", GA_TRACKING_ID, { user_id: userId })
  window.gtag("set", "user_properties", { auth0_id: userId })
}
