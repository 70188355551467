import React from "react"

export default function SearchLoadingIndicator() {
  const skeletonLoader = () => (
    <div className="flex flex-col animate-pulse mb-14">
      <div className="w-1/2 h-6 bg-gray-400 rounded mb-2"></div>
      <div className="w-full h-20 bg-gray-400 rounded mb-2"></div>
      <div className="w-3/12 h-4 bg-gray-400 rounded"></div>
    </div>
  )

  return <div className="max-w-full flex flex-col">{skeletonLoader()}</div>
}
