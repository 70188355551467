/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react"
import { useLocation } from "react-router-dom"

const GA_TRACKING_ID = process.env.REACT_APP_GA_ID

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
const pageview = (title) => {
  window.gtag("config", GA_TRACKING_ID, {
    page_title: title,
  })
}

export default function usePageViews() {
  const params = new URLSearchParams(useLocation().search)
  const keyword = params.get("keyword")

  useEffect(() => {
    pageview(keyword ? "Search" : "Home")
  }, [params])
}
