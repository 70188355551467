/* eslint-disable no-unused-vars */
import SearchAirtable from "@services/airtable"
import { useSWRInfinite } from "swr"

export default function useSearch(
  keyword,
  sortField,
  sortDirection,
  filterKeys,
  filterValues,
  fundingAmountMin,
  fundingAmountMax,
  startFromDate
) {
  const getKey = (pageIndex, previousPageData) => {
    if (previousPageData && previousPageData.error) return null // error

    if (previousPageData && !previousPageData.hasMorePages) return null // reached the end

    if (pageIndex === 0) {
      return JSON.stringify({
        keyword: keyword,
        isNewSearch: true,
        offset: undefined,
        sortField: sortField,
        sortDirection: sortDirection,
        filterKeys: filterKeys,
        filterValues: filterValues,
        fundingAmountMin: fundingAmountMin,
        fundingAmountMax: fundingAmountMax,
        startFromDate: startFromDate,
      })
    } // first page

    return JSON.stringify({
      keyword: keyword,
      isNewSearch: false,
      offset: previousPageData.nextTableOffset,
      sortField: sortField,
      sortDirection: sortDirection,
      filterKeys: filterKeys,
      filterValues: filterValues,
      fundingAmountMin: fundingAmountMin,
      fundingAmountMax: fundingAmountMax,
      startFromDate: startFromDate,
    })
  }

  const fetcher = async (queryString) => {
    let query = JSON.parse(queryString)
    let tableQuery = query
    tableQuery["offset"] = query.offset
    tableQuery["sortField"] = query.sortField
    tableQuery["sortDirection"] = query.sortDirection
    tableQuery["filterKeys"] = query.filterKeys
    tableQuery["filterValues"] = query.filterValues
    tableQuery["fundingAmountMin"] = query.fundingAmountMin
    tableQuery["fundingAmountMax"] = query.fundingAmountMax
    tableQuery["startFromDate"] = query.startFromDate

    try {
      return await SearchAirtable(tableQuery).then((response) => {
        let nextTableOffset = undefined
        let hasMorePages = false
        if (response.offset) {
          nextTableOffset = response.offset
          hasMorePages = true
        }
        let records = []
        if (response.records) {
          records = response.records
            .filter((result) => result.fields)
            .map((result) => result.fields)
        }

        return {
          results: records,
          nextTableOffset: nextTableOffset,
          hasMorePages: hasMorePages,
          error: null,
          viewStatus: records.length === 0 ? "empty" : "data",
        }
      })
    } catch (error) {
      return { error: error, viewStatus: "error" }
    }
  }

  const { data, size, setSize } = useSWRInfinite(getKey, fetcher, {
    dedupingInterval: 10000000,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  })

  let results = (data ?? []).reduce((accumulatedResults, response) => {
    if (response["results"] && response["results"].length > 0) {
      return accumulatedResults.concat(response["results"])
    }
    return accumulatedResults
  }, [])

  let hasMorePages = false
  let viewStatus = ""
  let error = null
  if (data && data.length > 0) {
    let lastResponse = data[data.length - 1]

    if (lastResponse) {
      hasMorePages = lastResponse.hasMorePages

      if (lastResponse.error) {
        viewStatus = "error"
        error = lastResponse.error
      } else {
        viewStatus = results.length === 0 ? "empty" : "data"
      }
    }
  }

  return {
    results: results,
    hasMorePages: hasMorePages,
    viewStatus: viewStatus,
    error: error,
    page: size,
    loadMore: setSize,
  }
}
