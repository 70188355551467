import { React, useContext } from "react"
import { useHistory } from "react-router-dom"
import * as gtag from "@utils/gtag"
import moment from "moment"
import { LanguageContext } from "@contexts/language-context"
import { AdvancedSearchContext } from "@contexts/advanced-search-context"

export default function SearchTag({ text, isHighlighted }) {
  const history = useHistory()

  const [language] = useContext(LanguageContext)
  const [selectedSearchOptions] = useContext(AdvancedSearchContext)

  const search = (keyword) => {
    gtag.event({
      action: "suggested_search",
      category: "Search",
      label: keyword,
    })

    let filterKeysQuery = ""
    let filterValuesQuery = ""
    // eslint-disable-next-line no-unused-vars
    for (const [_, object] of Object.entries(
      selectedSearchOptions.filteredBy
    )) {
      let localizaedKey = language === "en" ? object.field.en : object.field.tc
      let localizaedValue =
        language === "en" ? object.value.en : object.value.tc

      if (!localizaedValue || localizaedValue.trim() === "") {
        continue
      }

      filterKeysQuery = filterKeysQuery.concat(`&filterKeys=${localizaedKey}`)
      filterValuesQuery = filterValuesQuery.concat(
        `&filterValues=${localizaedValue}`
      )
    }

    let fundingAmountQuery = ""
    if (
      (!isNaN(Number(selectedSearchOptions.fundingAmountRange.min)) &&
        Number(selectedSearchOptions.fundingAmountRange.min) > 0) ||
      (!isNaN(Number(selectedSearchOptions.fundingAmountRange.max)) &&
        Number(selectedSearchOptions.fundingAmountRange.max) > 0)
    ) {
      fundingAmountQuery = `&fundingAmountMin=${selectedSearchOptions.fundingAmountRange.min}&fundingAmountMax=${selectedSearchOptions.fundingAmountRange.max}`
    }

    let startDateQuery = ""
    if (moment(selectedSearchOptions.startDate, "DD/MM/YYYY").isValid()) {
      startDateQuery = `&startFromDate=${selectedSearchOptions.startDate}`
    }

    history.push(
      `/search?keyword=${keyword}&sortField=${selectedSearchOptions.sortedBy.field[language]}&sortDirection=${selectedSearchOptions.sortedBy.direction}${filterKeysQuery}${filterValuesQuery}${fundingAmountQuery}${startDateQuery}`
    )
  }

  const tagTextStyle = () => {
    if (isHighlighted) {
      return "mx-4 my-2 text-blue-Default hover:text-blue-light text-2xl font-semibold"
    } else {
      return "mx-4 my-2 text-blue-Default hover:text-blue-light text-sm"
    }
  }

  return (
    <button
      className=" rounded-full bg-gray-200 hover:bg-gray-100"
      onClick={() => search(text)}
    >
      <p className={tagTextStyle()}>{text}</p>
    </button>
  )
}
