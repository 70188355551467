import React, { useState, createContext } from "react"
import cloneDeep from "lodash/cloneDeep"

export const AdvancedSearchOptions = {
  sortings: [
    {
      field: { en: "Project Title (Eng)", tc: "Project Title (Chi)" },
      direction: "asc",
      displayName: { en: "Project Title (A->Z)", tc: "項目名稱（A到Z）" },
    },
    {
      field: { en: "Project Title (Eng)", tc: "Project Title (Chi)" },
      direction: "desc",
      displayName: { en: "Project Title (Z->A)", tc: "項目名稱（Z到A）" },
    },
    {
      field: { en: "Grantee Name (Eng)", tc: "Grantee Name (Chi)" },
      direction: "asc",
      displayName: { en: "Grantee Name (A->Z)", tc: "受資助單位名稱（A到Z）" },
    },
    {
      field: { en: "Grantee Name (Eng)", tc: "Grantee Name (Chi)" },
      direction: "desc",
      displayName: { en: "Grantee Name (Z->A)", tc: "受資助單位名稱（Z到A）" },
    },
    {
      field: { en: "Funding Amount", tc: "Funding Amount" },
      direction: "asc",
      displayName: {
        en: "Funding Amount (From Lowest)",
        tc: "資助金額（低至高）",
      },
    },
    {
      field: { en: "Funding Amount", tc: "Funding Amount" },
      direction: "desc",
      displayName: {
        en: "Funding Amount (From Highest)",
        tc: "資助金額（高至低）",
      },
    },
    {
      field: { en: "Relevant Date", tc: "Relevant Date" },
      direction: "asc",
      displayName: { en: "Date (Old to New)", tc: "日期（舊至新）" },
    },
    {
      field: { en: "Relevant Date", tc: "Relevant Date" },
      direction: "desc",
      displayName: { en: "Date (New to Old)", tc: "日期（新至舊）" },
    },
  ],
  filters: {
    discipline: {
      fieldName: { en: "Project Discipline", tc: "項目種類" },
      type: "single select",
      options: [
        {
          field: { en: "Discipline", tc: "Discipline" },
          value: { en: "", tc: "" },
          label: { en: "All", tc: "全部" },
        },
        {
          field: { en: "Discipline", tc: "Discipline" },
          value: {
            en: "社企項目 Social Enterprise Projects",
            tc: "社企項目 Social Enterprise Projects",
          },
          label: { en: "Social Enterprise Project", tc: "社企項目" },
        },
        {
          field: { en: "Discipline", tc: "Discipline" },
          value: {
            en: "文藝項目 Art/Cultural Projects",
            tc: "文藝項目 Art/Cultural Projects",
          },
          label: { en: "Art/Cultural Project", tc: "文藝項目" },
        },
      ],
    },
    grantName: {
      fieldName: { en: "Grant Name", tc: "資助名稱" },
      type: "single select",
      options: [
        {
          field: {
            en: "Grant Name (Eng)",
            tc: "Grant Name (Chi)",
          },
          value: { en: "", tc: "" },
          label: { en: "All", tc: "全部" },
        },
        {
          field: {
            en: "Grant Name (Eng)",
            tc: "Grant Name (Chi)",
          },
          value: { en: "CSI", tc: "創意智優計劃" },
          label: { en: "CSI", tc: "創意智優計劃" },
        },
        {
          field: {
            en: "Grant Name (Eng)",
            tc: "Grant Name (Chi)",
          },
          value: { en: "CIIF", tc: "投資共享基金" },
          label: { en: "CIIF", tc: "投資共享基金" },
        },
        {
          field: {
            en: "Grant Name (Eng)",
            tc: "Grant Name (Chi)",
          },
          value: { en: "ADC", tc: "香港藝術發展局" },
          label: { en: "ADC", tc: "香港藝術發展局" },
        },
        {
          field: {
            en: "Grant Name (Eng)",
            tc: "Grant Name (Chi)",
          },
          value: { en: "PFD", tc: "擕手扶弱基金" },
          label: { en: "PFD", tc: "擕手扶弱基金" },
        },
        {
          field: {
            en: "Grant Name (Eng)",
            tc: "Grant Name (Chi)",
          },
          value: { en: "ESR", tc: "伙伴倡自強" },
          label: { en: "ESR", tc: "伙伴倡自強" },
        },
        {
          field: {
            en: "Grant Name (Eng)",
            tc: "Grant Name (Chi)",
          },
          value: { en: "EEE", tc: "創業展才能" },
          label: { en: "EEE", tc: "創業展才能" },
        },
        {
          field: {
            en: "Grant Name (Eng)",
            tc: "Grant Name (Chi)",
          },
          value: { en: "SIE", tc: "社創基金" },
          label: { en: "SIE", tc: "社創基金" },
        },
        {
          field: {
            en: "Grant Name (Eng)",
            tc: "Grant Name (Chi)",
          },
          value: { en: "ACDFS", tc: "藝能發展資助計劃" },
          label: { en: "ACDFS", tc: "藝能發展資助計劃" },
        },
      ],
    },
    grantCategory: {
      fieldName: { en: "Category of Grant", tc: "資助種類" },
      type: "single select",
      options: [
        {
          field: {
            en: "Category of Grant (Eng)",
            tc: "Category of Grant (Chi)",
          },
          value: { en: "", tc: "" },
          label: { en: "All", tc: "全部" },
        },
        {
          field: {
            en: "Category of Grant (Eng)",
            tc: "Category of Grant (Chi)",
          },
          value: { en: "SIE Prototype", tc: "社創基金原型" },
          label: { en: "SIE Prototype", tc: "社創基金原型" },
        },
        {
          field: {
            en: "Category of Grant (Eng)",
            tc: "Category of Grant (Chi)",
          },
          value: { en: "SIE Late prototype", tc: "社創基金成熟原型" },
          label: { en: "SIE Late prototype", tc: "社創基金成熟原型" },
        },
        {
          field: {
            en: "Category of Grant (Eng)",
            tc: "Category of Grant (Chi)",
          },
          value: { en: "SIE Start Up", tc: "社創基金創業" },
          label: { en: "SIE Start Up", tc: "社創基金創業" },
        },
        {
          field: {
            en: "Category of Grant (Eng)",
            tc: "Category of Grant (Chi)",
          },
          value: { en: "SIE Growing", tc: "社創基金成長" },
          label: { en: "SIE Growing", tc: "社創基金成長" },
        },
        {
          field: {
            en: "Category of Grant (Eng)",
            tc: "Category of Grant (Chi)",
          },
          value: { en: "SIE Scale Up", tc: "社創基金擴大規模" },
          label: { en: "SIE Scale Up", tc: "社創基金擴大規模" },
        },
        {
          field: {
            en: "Category of Grant (Eng)",
            tc: "Category of Grant (Chi)",
          },
          value: { en: "ADC Project Grant", tc: "藝發局計畫資助" },
          label: { en: "ADC Project Grant", tc: "藝發局計畫資助" },
        },
        {
          field: {
            en: "Category of Grant (Eng)",
            tc: "Category of Grant (Chi)",
          },
          value: { en: "CSI Design", tc: "創意智優(設計)" },
          label: { en: "CSI Design", tc: "創意智優(設計)" },
        },
        {
          field: {
            en: "Category of Grant (Eng)",
            tc: "Category of Grant (Chi)",
          },
          value: { en: "CSI Non-Design", tc: "創意智優(非設計)" },
          label: { en: "CSI Non-Design", tc: "創意智優(非設計)" },
        },
        {
          field: {
            en: "Category of Grant (Eng)",
            tc: "Category of Grant (Chi)",
          },
          value: { en: "ACDFS Springboard Grant", tc: "藝能發展躍進資助" },
          label: { en: "ACDFS Springboard Grant", tc: "藝能發展躍進資助" },
        },
        {
          field: {
            en: "Category of Grant (Eng)",
            tc: "Category of Grant (Chi)",
          },
          value: { en: "ACDFS Project Grant", tc: "藝能發展項目計劃資助" },
          label: { en: "ACDFS Project Grant", tc: "藝能發展項目計劃資助" },
        },
      ],
    },
    intermediary: {
      fieldName: { en: "Intermediary", tc: "協創機構" },
      type: "single select",
      options: [
        {
          field: { en: "Intermediary (Eng)", tc: "Intermediary (Chi)" },
          value: { en: "", tc: "" },
          label: { en: "All", tc: "全部" },
        },
        {
          field: { en: "Intermediary (Eng)", tc: "Intermediary (Chi)" },
          value: { en: "BEHub", tc: "社創社" },
          label: { en: "BEHub", tc: "社創社" },
        },
        {
          field: { en: "Intermediary (Eng)", tc: "Intermediary (Chi)" },
          value: { en: "Good Seed", tc: "好薈社" },
          label: { en: "Good Seed", tc: "好薈社" },
        },
        {
          field: { en: "Intermediary (Eng)", tc: "Intermediary (Chi)" },
          value: { en: "Impact Incubator", tc: "創匯點" },
          label: { en: "Impact Incubator", tc: "創匯點" },
        },
        {
          field: { en: "Intermediary (Eng)", tc: "Intermediary (Chi)" },
          value: { en: "Innovator Farm", tc: "創新園" },
          label: { en: "Innovator Farm", tc: "創新園" },
        },
      ],
    },
    artForm: {
      fieldName: { en: "Art Form", tc: "藝術形式" },
      type: "single select",
      options: [
        {
          field: {
            en: "Art Form (Eng)",
            tc: "Art Form (Chi)",
          },
          value: { en: "", tc: "" },
          label: { en: "All", tc: "全部" },
        },
        {
          field: {
            en: "Art Form (Eng)",
            tc: "Art Form (Chi)",
          },
          value: { en: "Dance", tc: "舞蹈" },
          label: { en: "Dance", tc: "舞蹈" },
        },
        {
          field: {
            en: "Art Form (Eng)",
            tc: "Art Form (Chi)",
          },
          value: { en: "Film and Media Arts", tc: "電影及媒體藝術" },
          label: { en: "Film and Media Arts", tc: "電影及媒體藝術" },
        },
        {
          field: {
            en: "Art Form (Eng)",
            tc: "Art Form (Chi)",
          },
          value: {
            en: "Cross/Multi-disciplinary Arts",
            tc: "跨媒介/跨界別",
          },
          label: {
            en: "Cross/Multi-disciplinary Arts",
            tc: "跨媒介/跨界別",
          },
        },
        {
          field: {
            en: "Art Form (Eng)",
            tc: "Art Form (Chi)",
          },
          value: { en: "Visual Arts", tc: "視覺藝術" },
          label: { en: "Visual Arts", tc: "視覺藝術" },
        },
        {
          field: {
            en: "Art Form (Eng)",
            tc: "Art Form (Chi)",
          },
          value: { en: "Drama", tc: "戲劇" },
          label: { en: "Drama", tc: "戲劇" },
        },
        {
          field: {
            en: "Art Form (Eng)",
            tc: "Art Form (Chi)",
          },
          value: { en: "Literary Arts", tc: "文學藝術" },
          label: { en: "Literary Arts", tc: "文學藝術" },
        },
        {
          field: {
            en: "Art Form (Eng)",
            tc: "Art Form (Chi)",
          },
          value: { en: "Music", tc: "音樂" },
          label: { en: "Music", tc: "音樂" },
        },
        {
          field: {
            en: "Art Form (Eng)",
            tc: "Art Form (Chi)",
          },
          value: { en: "Xiqu", tc: "戲曲" },
          label: { en: "Xiqu", tc: "戲曲" },
        },
      ],
    },
    district: {
      fieldName: { en: "Project District", tc: "項目地區" },
      type: "single select",
      options: [
        {
          field: { en: "Project District (Eng)", tc: "Project District (Chi)" },
          value: { en: "", tc: "" },
          label: { en: "Hong Kong", tc: "全港" },
        },
        {
          field: { en: "Project District (Eng)", tc: "Project District (Chi)" },
          value: { en: "Central and Western", tc: "中西區" },
          label: { en: "Central and Western", tc: "中西區" },
        },
        {
          field: { en: "Project District (Eng)", tc: "Project District (Chi)" },
          value: { en: "Eastern", tc: "東區" },
          label: { en: "Eastern", tc: "東區" },
        },
        {
          field: { en: "Project District (Eng)", tc: "Project District (Chi)" },
          value: { en: "Southern", tc: "南區" },
          label: { en: "Southern", tc: "南區" },
        },
        {
          field: { en: "Project District (Eng)", tc: "Project District (Chi)" },
          value: { en: "Wan Chai", tc: "灣仔區" },
          label: { en: "Wan Chai", tc: "灣仔區" },
        },
        {
          field: { en: "Project District (Eng)", tc: "Project District (Chi)" },
          value: { en: "Sham Shui Po", tc: "深水埗區" },
          label: { en: "Sham Shui Po", tc: "深水埗區" },
        },
        {
          field: { en: "Project District (Eng)", tc: "Project District (Chi)" },
          value: { en: "Kowloon City", tc: "九龍城區" },
          label: { en: "Kowloon City", tc: "九龍城區" },
        },
        {
          field: { en: "Project District (Eng)", tc: "Project District (Chi)" },
          value: { en: "Kwun Tong", tc: "觀塘區" },
          label: { en: "Kwun Tong", tc: "觀塘區" },
        },
        {
          field: { en: "Project District (Eng)", tc: "Project District (Chi)" },
          value: { en: "Wong Tai Sing", tc: "黃大仙區" },
          label: { en: "Wong Tai Sing", tc: "黃大仙區" },
        },
        {
          field: { en: "Project District (Eng)", tc: "Project District (Chi)" },
          value: { en: "Yau Tsim Mong", tc: "油尖旺區" },
          label: { en: "Yau Tsim Mong", tc: "油尖旺區" },
        },
        {
          field: { en: "Project District (Eng)", tc: "Project District (Chi)" },
          value: { en: "Islands", tc: "離島區" },
          label: { en: "Islands", tc: "離島區" },
        },
        {
          field: { en: "Project District (Eng)", tc: "Project District (Chi)" },
          value: { en: "Kwai Tsing", tc: "葵青區" },
          label: { en: "Kwai Tsing", tc: "葵青區" },
        },
        {
          field: { en: "Project District (Eng)", tc: "Project District (Chi)" },
          value: { en: "North", tc: "北區" },
          label: { en: "North", tc: "北區" },
        },
        {
          field: { en: "Project District (Eng)", tc: "Project District (Chi)" },
          value: { en: "Sai Kung", tc: "西貢區" },
          label: { en: "Sai Kung", tc: "西貢區" },
        },
        {
          field: { en: "Project District (Eng)", tc: "Project District (Chi)" },
          value: { en: "Sha Tin", tc: "沙田區" },
          label: { en: "Sha Tin", tc: "沙田區" },
        },
        {
          field: { en: "Project District (Eng)", tc: "Project District (Chi)" },
          value: { en: "Tai Po", tc: "大埔區" },
          label: { en: "Tai Po", tc: "大埔區" },
        },
        {
          field: { en: "Project District (Eng)", tc: "Project District (Chi)" },
          value: { en: "Tsuen Wan", tc: "荃灣區" },
          label: { en: "Tsuen Wan", tc: "荃灣區" },
        },
        {
          field: { en: "Project District (Eng)", tc: "Project District (Chi)" },
          value: { en: "Tuen Mun", tc: "屯門區" },
          label: { en: "Tuen Mun", tc: "屯門區" },
        },
        {
          field: { en: "Project District (Eng)", tc: "Project District (Chi)" },
          value: { en: "Yuen Long", tc: "元朗區" },
          label: { en: "Yuen Long", tc: "元朗區" },
        },
      ],
    },
  },
}

export const AdvancedSearchContext = createContext()

export const AdvancedSearchDefaultOptions = {
  sortedBy: AdvancedSearchOptions.sortings[0],
  filteredBy: {
    discipline: AdvancedSearchOptions.filters.discipline.options[0],
    grantCategory: AdvancedSearchOptions.filters.grantCategory.options[0],
    grantName: AdvancedSearchOptions.filters.grantName.options[0],
    artForm: AdvancedSearchOptions.filters.artForm.options[0],
    intermediary: AdvancedSearchOptions.filters.intermediary.options[0],
    district: AdvancedSearchOptions.filters.district.options[0],
  },
  fundingAmountRange: {
    min: 0,
    max: "",
  },
  startDate: "",
}

export const AdvancedSearchContextProvider = (props) => {
  // Create a deep copy default option,
  // Otherwise AdvancedSearchDefaultOptions would be changed!
  // https://stackoverflow.com/a/56105426/11261557
  let initialOptions = cloneDeep(AdvancedSearchDefaultOptions)
  const [selectedSearchOptions, setSelectedSearchOptions] = useState(
    initialOptions
  )

  return (
    <AdvancedSearchContext.Provider
      value={[selectedSearchOptions, setSelectedSearchOptions]}
    >
      {props.children}
    </AdvancedSearchContext.Provider>
  )
}
