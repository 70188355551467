import { useContext } from "react"
import { Popover } from "@headlessui/react"
import { faInfo } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { LanguageContext } from "@contexts/language-context"

export default function FloatingPopover() {
  const [language] = useContext(LanguageContext)

  return (
    <Popover className="relative">
      <Popover.Button className="focus:outline-none">
        <div className="inline-flex items-center justify-center filter drop-shadow-md h-10 lg:h-16 w-10 lg:w-16 text-sm lg:text-xl font-bold text-white bg-blue-Default border border-transparent rounded-full hover:bg-blue-light active:bg-blue-dark opacity-80">
          <FontAwesomeIcon icon={faInfo} />
        </div>
      </Popover.Button>

      <Popover.Overlay className={`opacity-30 fixed inset-0 bg-black`} />

      <Popover.Panel className="absolute bottom-14 lg:bottom-20 right-0 w-64 sm:w-96 z-50">
        <div className="flex flex-col filter drop-shadow-md bg-gray-600 p-5 lg:p-10 rounded-md">
          <div className="pb-2 text-sm md:text-lg font-bold text-white">
            {language === "en" ? "Grant Name" : "資助名稱"}
          </div>
          <ul className="list-inside list-disc text-xs md:text-base text-gray-50">
            <li>
              <span className="font-semibold">ACDFS</span>:{" "}
              {language === "en"
                ? "Arts Capacity Development Funding Scheme"
                : "藝能發展資助計劃"}
            </li>
            <li>
              <span className="font-semibold">ADC</span>:{" "}
              {language === "en"
                ? "Arts Development Council Grants"
                : "香港藝術發展局"}
            </li>
            <li>
              <span className="font-semibold">CIIF</span>:{" "}
              {language === "en"
                ? "Community Investment and Inclusion Fund"
                : "投資共享基金"}
            </li>
            <li>
              <span className="font-semibold">CSI</span>:{" "}
              {language === "en" ? "CreateSmart Initiative" : "創意智優計劃"}
            </li>
            <li>
              <span className="font-semibold">EEE</span>:{" "}
              {language === "en"
                ? "Enhancing Employment of People with Disabilities through Small Enterprise Project"
                : "創業展才能"}
            </li>
            <li>
              <span className="font-semibold">ESR</span>:{" "}
              {language === "en"
                ? "Enhancing Self-Reliance Programme"
                : "伙伴倡自強"}
            </li>
            <li>
              <span className="font-semibold">SIE</span>:{" "}
              {language === "en"
                ? "Social Innovation and Entrepreneurship Development Fund"
                : "社創基金"}
            </li>
            <li>
              <span className="font-semibold">PFD</span>:{" "}
              {language === "en"
                ? "Partnership Fund for the Disadvantaged"
                : "擕手扶弱基金"}
            </li>
          </ul>
        </div>
      </Popover.Panel>
    </Popover>
  )
}
