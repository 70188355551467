import { React, useContext } from "react"
import SearchTag from "@components/search-tag"
import { LanguageContext } from "@contexts/language-context"

export default function SearchSuggestions() {
  const [language] = useContext(LanguageContext)

  const tagsEn = [
    { text: "Job opportunities", isHighlighted: false },
    { text: "Low-income", isHighlighted: false },
    { text: "SEN", isHighlighted: false },
    { text: "Disabled", isHighlighted: false },
    { text: "Restaurant", isHighlighted: false },
    { text: "Food production", isHighlighted: false },
    { text: "Multi/Cross-disciplinary", isHighlighted: false },
    { text: "Gaming", isHighlighted: false },
    { text: "Drama", isHighlighted: false },
    { text: "Contemporary Dance", isHighlighted: false },
  ]

  const tagsTc = [
    { text: "就業機會", isHighlighted: false },
    { text: "低收入", isHighlighted: false },
    { text: "特殊學習需要", isHighlighted: false },
    { text: "殘疾", isHighlighted: false },
    { text: "餐飲", isHighlighted: false },
    { text: "食品製作", isHighlighted: false },
    { text: "跨媒介", isHighlighted: false },
    { text: "遊戲", isHighlighted: false },
    { text: "話劇", isHighlighted: false },
    { text: "當代舞", isHighlighted: false },
  ]

  let tags = tagsEn
  if (language === "tc") {
    tags = tagsTc
  }

  const suggestionItem = (tag, index) => (
    <div key={index} className="mr-2 mb-2">
      <SearchTag text={tag.text} isHighlighted={tag.isHighlighted} />
    </div>
  )

  return (
    <div className="">
      <p className="text-gray-500 font-bold mb-4">
        {language === "en" ? "Search Suggestions" : "熱門搜尋"}
      </p>

      <div className="flex flex-wrap items-center">
        {tags.map(suggestionItem)}
      </div>
    </div>
  )
}
