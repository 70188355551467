import React from "react"
import { Switch, Route } from "react-router-dom"
import usePageViews from "@hooks/use-page-views"
import { LanguageContextProvider } from "@contexts/language-context"
import { AdvancedSearchContextProvider } from "@contexts/advanced-search-context"
import Logo from "@components/logo"
import SearchForm from "@components/search-form"
import SearchSuggestions from "@components/search-suggestions"
import SearchResults from "@components/search-results"
import NotFound404 from "@components/404-not-found"
import NavBar from "@components/nav-bar"
import Footer from "@components/footer"
import FloatingPopover from "@components/floating-popover"
import { useAuth0 } from "@auth0/auth0-react"
// import EmailVerification from "@components/email-verification"
// import Features from "@components/features"
import VerifiedMessage from "@components/verified-message"
// import * as gtag from "@utils/gtag"
// eslint-disable-next-line
// import Welcome from "@components/welcome"

export default function App() {
  usePageViews()

  // const { user, isAuthenticated, isLoading, error } = useAuth0()
  const { isLoading, error } = useAuth0()

  if (error) {
    // return <div>Oops... {error.message}</div>
  }

  if (isLoading) {
    return (
      <div className="flex justify-center content-center items-center w-full min-h-screen animate-pulse ">
        <div className="h-4 w-4 bg-gray-400 rounded mr-2"></div>
        <div className="h-4 w-4 bg-gray-400 rounded mr-2"></div>
        <div className="h-4 w-4 bg-gray-400 rounded mr-2"></div>
        <div className="h-4 w-4 bg-gray-400 rounded"></div>
      </div>
    )
  }

  // if (isAuthenticated && !!user) {
  //   gtag.setUserId(user.sub)
  // }

  return (
    <LanguageContextProvider>
      <div className="flex flex-col items-center w-full min-h-screen">
        <div className="w-full fixed z-30">
          <NavBar />
        </div>
        <main className="flex flex-col mt-16 container mx-auto max-w-full md:max-w-2xl lg:max-w-3xl xl:max-w-4xl py-8 xl:py-16 px-8 xl:px-16 z-10">
          <div className="justify-center">
            <Logo />
          </div>

          {/* {!isAuthenticated && (
            <Switch>
              <Route exact path="/">
                <div className="mt-12 sm:mt-20 sm:mx-10 xl:mx-24 justify-center">
                  <Features />
                </div>
              </Route>
              <Route exact path="/verified">
                <div className="mt-12 sm:mt-20 sm:mx-10 xl:mx-24 justify-center">
                  <VerifiedMessage />
                </div>
              </Route>
              <Route path="*">
                <NotFound404 />
              </Route>
            </Switch>
          )} */}

          {/* {isAuthenticated && !user.email_verified && (
            <div className="mt-12 sm:mt-20 sm:mx-10 xl:mx-24 justify-center">
              <EmailVerification />
            </div>
          )} */}

          {/* {isAuthenticated && user.email_verified && ( */}
          {true && (
            <AdvancedSearchContextProvider>
              <div className="mt-12 sm:mt-20 sm:mx-10 xl:mx-24 justify-center">
                <Switch>
                  <Route exact path="/">
                    {/* <div>
                      <Welcome />
                    </div> */}
                    <SearchForm />
                  </Route>
                  <Route exact path="/search">
                    {/* <div>
                      <Welcome />
                    </div> */}
                    <SearchForm />
                  </Route>
                  <Route exact path="/verified">
                    <div className="mt-12 sm:mt-20 sm:mx-10 xl:mx-24 justify-center">
                      <VerifiedMessage />
                    </div>
                  </Route>
                  <Route path="*">
                    <NotFound404 />
                  </Route>
                </Switch>
              </div>

              <div className="mt-12 sm:mt-14 sm:mx-10 xl:mx-24 mb-16">
                <Switch>
                  <Route exact path="/">
                    <SearchSuggestions />
                  </Route>
                  <Route exact path="/search">
                    <SearchResults />
                  </Route>
                  <Route path="*">
                    <></>
                  </Route>
                </Switch>
              </div>
            </AdvancedSearchContextProvider>
          )}
        </main>
        <div className="w-full fixed bottom-0 z-20">
          <Footer />
        </div>
        {/* {isAuthenticated && user.email_verified && ( */}
        {true && (
          <div className="fixed bottom-14 md:bottom-16 right-6 md:right-20 lg:right-32 xl:right-64 z-40">
            <FloatingPopover />
          </div>
        )}
      </div>
    </LanguageContextProvider>
  )
}
