import moment from "moment"

const keywordQueryForField = (keyword, fieldName) => {
  return `FIND(LOWER("${keyword}"), LOWER({${fieldName}}))`
}

export default async function SearchAirtable(query) {
  const {
    keyword,
    offset,
    sortField,
    sortDirection,
    filterKeys,
    filterValues,
    fundingAmountMin,
    fundingAmountMax,
    startFromDate,
  } = query

  let keywords = keyword.replaceAll(/[ /,;&]+/g, " ").split(" ")
  let keywordFilters = []

  keywords.forEach((keyword) => {
    let singleKeywodFilters = []
    singleKeywodFilters.push(
      keywordQueryForField(keyword, "Project Title (Eng)")
    )
    singleKeywodFilters.push(
      keywordQueryForField(keyword, "Project Title (Chi)")
    )
    singleKeywodFilters.push(
      keywordQueryForField(keyword, "Grantee Name (Eng)")
    )
    singleKeywodFilters.push(
      keywordQueryForField(keyword, "Grantee Name (Chi)")
    )
    singleKeywodFilters.push(
      keywordQueryForField(keyword, "Project Summary (Eng)")
    )
    singleKeywodFilters.push(
      keywordQueryForField(keyword, "Project Summary (Chi)")
    )
    singleKeywodFilters.push(
      keywordQueryForField(
        keyword,
        "Partnering/Related Organisation Name (Eng)"
      )
    )
    singleKeywodFilters.push(
      keywordQueryForField(
        keyword,
        "Partnering/Related Organisation Name (Chi)"
      )
    )
    singleKeywodFilters.push(keywordQueryForField(keyword, "sehji Tags"))
    singleKeywodFilters.push(
      keywordQueryForField(keyword, "Project Description (Eng)")
    )
    singleKeywodFilters.push(
      keywordQueryForField(keyword, "Project Description (Chi)")
    )

    keywordFilters.push(`OR(${singleKeywodFilters.join(", ")})`)
  })

  let finalFilters = []
  finalFilters.push(`AND(${keywordFilters.join(", ")})`)

  filterKeys.forEach((filterKey, index) => {
    let filterValue = filterValues[index]
    finalFilters.push(keywordQueryForField(filterValue, filterKey))
  })

  // if (tableName === "SIE 社創基金") {
  //   finalFilters.push(`{Source Status} = "Listing"`)
  // }

  if (
    !isNaN(Number(fundingAmountMin)) &&
    Number(fundingAmountMin) > 0 &&
    !isNaN(Number(fundingAmountMax)) &&
    Number(fundingAmountMax) > 0
  ) {
    finalFilters.push(`{Funding Amount} >= ${fundingAmountMin}`)
    finalFilters.push(`{Funding Amount} <= ${fundingAmountMax}`)
  } else if (!isNaN(Number(fundingAmountMin)) && Number(fundingAmountMin) > 0) {
    finalFilters.push(`{Funding Amount} >= ${fundingAmountMin}`)
  } else if (!isNaN(Number(fundingAmountMax)) && Number(fundingAmountMax) > 0) {
    finalFilters.push(`{Funding Amount} <= ${fundingAmountMax}`)
  }

  if (moment(startFromDate, "DD/MM/YYYY").isValid()) {
    finalFilters.push(
      `OR(IS_SAME({Relevant Date}, "${startFromDate}", "day"), IS_AFTER({Relevant Date}, "${startFromDate}"))`
    )
  }

  let filtersQuery = `AND(${finalFilters.join(", ")})`

  let offsetQuery = ""
  if (offset && offset.length > 0) {
    offsetQuery = `&offset=${offset}`
  }

  let sorting = `&sort[0][field]=${sortField}&sort[0][direction]=${sortDirection}`

  return await fetch(
    `api/search?filterByFormula=${encodeURIComponent(
      filtersQuery
    )}${offsetQuery}${sorting}`,
    {
      method: "GET",
    }
  )
    .then((response) => response.json())
    .then((response) => {
      return response
    })
}
