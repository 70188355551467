import React from "react"

export default function NotFound404() {
  return (
    <div className="text-center mt-20">
      <p className="text-xl md:text-3xl tracking-normal font-bold text-gray-600">
        The requested page does not exist
      </p>
      <p className="mt-3 text-xl md:text-3xl tracking-normal font-bold text-gray-600">
        請求的頁面不存在
      </p>
    </div>
  )
}
