import React, { useContext } from "react"
import logo from "@assets/logo.svg"
import { LanguageContext } from "@contexts/language-context"

export default function Logo() {
  const [language] = useContext(LanguageContext)

  return (
    <div className="flex flex-col text-center">
      <div className="flex justify-center">
        <div className="w-32 md:w-52">
          <a href="https://sehji.co" target="__blank">
            <img src={logo} alt="SehJi Logo" />
          </a>
        </div>
      </div>
      <p className="text-xl sm:text-4sm tracking-normal font-thin text-black-300 mt-2 sm:mt-3">
        {language === "en"
          ? "Publicly Funded Social Enterprise and Art/Cultural Projects Search"
          : "「寫字」資料庫：受政府資助的社企及文藝項目"}
      </p>
    </div>
  )
}
